.partners{
    padding: 5rem 0;
}

.partners__icons{
    gap: 2rem;
}
.partners-icons img{
    width: 140px;
    height: auto;
    object-fit: cover;
}
.partners__header-title, .partners__trayectoria-title, .trayectoria__counter span{
    color: var(--primary);
}
.trayectoria__icon img{
    width: 80px;
    height: 80px;
    object-fit: contain;
}
.trayectoria__texts{
    padding-right: 12rem;
}
@media only screen and (max-width: 1024px){
    .partners{
        padding: 3rem 1rem;
    }
    .partners__trayectoria-title{
        text-align: center;
    }
    .trayectoria__texts{
        padding: 0;
    }
    .trayectoria{
        padding: 0 1rem;
        box-sizing: border-box;
    }
    .trayectoria__counters{
        text-align: center;
    }
}
@media only screen and (max-width: 768px){
    .partners{
        padding: 5rem 1rem;
    }
}
@media only screen and (max-width: 480px){
    .partners-icons img{
        width: 100px;
    }
}