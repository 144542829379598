.abouts{
    padding: 6rem 0;
}
.abouts__header{
    max-width: 800px;
}
.abouts__header-title{
    color: var(--primary);
}
.abouts__header-subtitle{
    line-height: 1.25;
}
.abouts__items{
    /*
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    grid-gap: 1.5rem;
    */
}
.abouts__item{
    margin-top: 3rem;
    text-align: justify;
}
.abouts__item-subtitle{
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.abouts__item > p{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.abouts__item > ul{
    list-style: disc;
}
.abouts__item > ul > li{
    padding-left: 1rem;
    margin: 0 1rem;
}


.abouts__item-content{
    gap: 1rem;
}
.abouts__item-icon{
    background: #eff6ff;
    border-radius: 50%;
    padding: 1rem;
    box-sizing: border-box;
    max-width: 80px;
    height: 80px;
}
.abouts__item-img{

}
@media only screen and (max-width: 1024px){
    .abouts{
        padding: 3rem 1rem;
    }
    .abouts__items{
        grid-template-columns: repeat(3, 1fr);
        margin-top: 1rem;
        grid-gap: .4rem;
    }
}
@media only screen and (max-width: 768px){
    .abouts__items{
        grid-template-columns: repeat(3, 1fr);
        margin-top: 1rem;
        grid-gap: .4rem;
    }
}
@media only screen and (max-width: 480px) {
    .abouts{
        padding: 5rem 1rem;
    }
    .abouts__item{
        margin-top: 1rem;
    }
    .abouts__items{
        grid-template-columns: repeat(1, 1fr);
        margin-top: 1rem;
        grid-gap: .4rem;
    }
}