.header__navbar{
    color: white;
    background: transparent;
    border-bottom: 1px solid white ;
}
.header__navbar .header__navbar-item {
    color: white;
    font-weight: 600;
    transition: all .4s;
    cursor: pointer;
}
.header__navbar .header__navbar-item:hover{
    background: transparent;
    color: var(--primary);
}
.header__navbar-white{
    color: var(--third);
    background: white;
    border: none;
}
.header__navbar-white .header__navbar-item {
    color: var(--third);
    font-weight: 600;
}

.header__navbar-menu{
    color: white;
    margin-left: 0;
}
.header__navbar-menu-c{
    color: var(--third);
    margin-left: 0;
}
.header__support-icon{
    margin-right: .4rem !important;
}
.header__navbar-menu:hover, .header__navbar-menu-c:hover{
    background: transparent;
}
.header__navbar-brand{
    align-items: center;
    background-color: var(--third);
    justify-content: space-between;
}
.header__navbar-brand-white{
    background: white;
}

.header__navbar-button{
    background: transparent !important;
}
.header__logo{
    width: 200px !important;
    height: auto !important;
    max-height: 65px !important;
}

.menu__hidden-icon{
    cursor: pointer;
    color: white;
    transition: all .3s;
}
.menu__hidden-icon-color{
    color: var(--third);
}
.menu__hidden-icon:hover{
    color: var(--primary);
}

.menuTooltip{
    width: 30%;
    position: fixed;
    right: 0;
    height: 100vh;
    z-index: 999;

}
.menuTooltip__body{
    height: 100%;
}
.menuTooltip__icon{
    background-color: var(--primary);
    border-radius: .5rem;
    color: white;
    cursor: pointer;
    height: 48px;
    width: 48px;
    position: absolute;
    top: 1rem;
}
.menuTooltip__body-titles{
    color: var(--primary);
}
.menu__hidden-brand{
    display: none;
    margin-right: 1rem;
}
@media only screen and (max-width: 1024px){
    .menu__hidden-brand{
        display: block;
    }
}
@media only screen and (max-width: 768px){
    .menuTooltip{
        width: 50%;
    }
    .menu__hidden{
        display: none;
    }
    .header__navbar-menu-color{
        background: var(--third);
    }
    .header__social-icon{
        margin-top: 2rem;
    }
    /* .menu__hidden-icon{} */
}
@media only screen and (max-width: 480px){
    .menuTooltip{
        width: 80%;
    }
    .header__logo{
        width: 90px !important;
    }
    .header__navbar-brand .navbar-item{
        width: 60px;
        margin-right: calc(90% - 130px);
        padding-left: 0;
        
    }
}