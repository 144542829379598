.footer{
    color: white;
    background: var(--secondary);
    padding: 0 0 5rem;
}
.footer p{
    color: white;
}
.footer__logo{
    width: 200px;
}
.footer__about{
    padding-top: 4rem;
}
.footer__social{
    border-bottom: 1px solid;
}
.footer__info-content{
    margin-top: 0;
}
.footer__links{
    
}
.footer__links-title{
    font-weight: 600;
    color: var(--primary);
}
.footer a{
    color: white;
    transition: all .3s ease-in-out;
}
.footer a:hover{
    color: var(--primary);
}
@media only screen and (max-width: 768px){
    .footer{
        padding: 0 1rem;
    }
}
.footer .header__navbar-item{
    line-height: 1;
}
.footer .header__navbar-item:hover{
    background-color: transparent;
}
@media only screen and (max-width: 480px){

    .footer__links-content{
        flex-direction: column;
    }
    .footer__links-items{
        width: 100%;
        margin-top: 1rem;
    }
}