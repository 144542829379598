.team{
    padding: 5rem 0;
    background: var(--third);
    color: white;
}
.team__head-title{
    color: var(--primary);
}
.team__body{
    margin-top: 5rem;
}
.team__item{
    background: var(--primary);
    color: white;
    max-width: 200px;
}

.team p{
    color: white;
}
.team__item-img{
    border-radius: 50%;
    object-fit: cover;
    width: 150px;
    height: 150px;
}

@media only screen and (max-width: 480px){
    .team{
        padding: 5rem 1rem;
        background: var(--third);
        color: white;
    }
}