.slide__content{
    display: block;
    position: relative;
    height: 100vh;
}
.slide {
    height: 100vh;
    background-size: cover !important;
  }

  .slider__content {
    background-color: rgba(0, 0, 51, .65);
    background-repeat: no-repeat;
    background-position: center center;
    background-blend-mode: multiply;
    background-size: cover;
    height: 100vh;
  }
  .slider__content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .slider__content .inner button{
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  
  .slider__content .inner h1 {
    font-weight: 900;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
  }
  
  .slider__content .inner p {
    color: #FFFFFF;
    font-size: 1.5rem;
    line-height: 1;
    max-width: 640px;
    margin-top: 2rem;
  }
  .slider__content .cta {
    margin-top: 2rem;
  }
  
  @media (max-height: 500px) {
    .slider-wrapper, .slide {
      height: calc(100vh - 75px);
    }    
  }
  
  @media (max-width: 640px) {
    .slider__content .inner p{
      font-size: 1rem;
    }
  }
  
  @media (max-height: 600px) {
    .slider__content .inner h1 {
      font-size: 24px;
    }
    .slider__content .inner p{
      font-size: 1rem;
    }
  }
  
  @media (max-width: 640px) {
    .slider__content .inner h1 {
      font-size: 32px;
    }
  }

  /* Slide  */

  .slider__content h1 {
	transition: all 0.3s ease;
		transform: translateY(-20px);
	  opacity: 0;
}

.slider__content button {
	transition: all 0.3s ease;
	transform: translateY(20px);
	opacity: 0;
}

.slider__content p {
	transition: all 0.3s ease;
	transform: translateY(20px);
	opacity: 0;
}




.slider__content.is-visible h1,
.slider__content.is-visible button,
.slider__content.is-visible p{
	opacity: 1;
	transform: translateX(0);
}

.slider__content.is-visible p{
  transition-delay: .5s;
}

.slider__content.is-visible button {
					transition-delay: .7s;
}
