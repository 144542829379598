.contact{
    padding: 6rem 0;
}
.contact__info-title{
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
}
.contact__info-subtitle{
    font-weight: 700;
    text-transform: uppercase;
}
.contact__info-data{
    gap: 1rem;
}
.contact__info-data-title{
    font-weight: 700;
    color: var(--primary);
}
.contact__icons{
    background: #edf6fe;
    border-radius: 50%;
    width: 80px;
    height: 80px;
}
.contact__icons-img{
    max-width: 50px;
    height: auto;
    object-fit: contain;
}
@media only screen and (max-width: 1024px){
    .contact{
        padding: 3rem 1rem;
    }
}
@media only screen and (max-width: 480px){
    .contact__icons{
        width: 50px;
        height: 50px;
    }
    .contact__icons-img{
        width: 30px;
    }
}