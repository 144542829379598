:root{
    --primary: #4889c8;
    --secondary: #0D0F18;
    --third: rgb(0, 0, 51); 
}

body{
    font-family: 'IBM Plex Sans', sans-serif;
}
p{
    color: #6f7073
}
/* Social icons  */
.social__icons {
    width: 2rem;
    height: 2rem;
}
.social__icon{
    cursor: pointer;
    color: white;
    transition: all .4s ease-out;
}
/* .social__icons:hover{
    border: solid 1px var(--primary);
} */
    .social__icon:hover{
        color: var(--primary);
    }
    .social__icons:hover{
        background: white;
        border-radius: .4rem;
    }
    .social__icons:hover .social__icon{
        color: var(--primary);
    }
.primary{
    color:  var(--primary);
    transition: all .4s;
}
.secondary{
    color: var(--secondary);
    transition: all .4s;
}

.form__select, .select__expanded{
    width: 100%;
}
.splide__pagination{
    bottom: -2rem !important;
}
.splide__pagination__page.is-active{
    background: var(--primary) !important;
}

/* /Buton  */

.btn__primary{
    background-color: var(--primary);
    color: white;
    border: var(--primary);
    transition: all .4s;
    font-weight: 600;
}
.btn__primary:hover, .btn__primary:focus{
    background-color: var(--third);
    color: white;
}

/* modal style  */
.modal__head{
    background-color: white;
}
.modal__title{
    color: var(--primary);
    font-weight: 600;
}