.splash{
    height: 100vh;
    width: 100%;
    z-index: 9999;
    position: fixed;
    overflow:hidden;
    background: var(--secondary);
    color: white;
}
.splash, .splash1{
    animation-name: right;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

@keyframes right {
    80%{
        transform: translate(100%);

    }
    100%{
        opacity: 0;
    }
}
.splash2{
    background-color: #fa6901;
    animation-name: top;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}
@keyframes top {
    100%{
        transform: translateY(-100%);
        opacity: 0;
    }
}

.splash3{
    background-color: var(--orange);
    animation-name: left;
    animation-delay: 1.3s;
    animation-fill-mode: forwards;
}
@keyframes left {
    100%{
        transform: translateX(-100%);
        opacity: 0;
    }
}