.testimonials{
    background: url('../../assets/testimonial-bg.png') no-repeat center bottom / cover;
    padding: 6rem 0;
}
.testimonial__carrousel{
    max-width: 800px;
}
.testimonial__carrousel-photo{
    width: 200px;
    height: 200px;
    background-color: #dcecfe;
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;
}
    .testimonial__carrousel-photo::after{
        background-image: url('../../assets/quote.png');
        background-size: cover;
        border-radius: 50%;
        bottom: 0;
        color: white;
        content: '';
        display: flex;
        font-size: 64px;
        height: 50px;
        justify-content: center;
        line-height: 1.25;
        position: absolute;
        right:1rem;
        width: 50px;
    }

.testimonial__carrousel-message{
    line-height: 1.25;
}
@media only screen and (max-width: 1024px){}
@media only screen and (max-width: 768px){
    .testimonials{
        padding: 5rem 1rem;
    }
    .testimonial__carrousel{
        max-width: 500px;
    }
}
@media only screen and (max-width: 480px){
    .testimonials{
        padding: 5rem 1rem;
    }
    .testimonial__carrousel{
        max-width: 350px;
    }
}